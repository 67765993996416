// Clock.js

import { $ } from "./Utils.js";

export default class Clock {
  constructor(selector, autoStart = false) {
    this.timeElement = $(selector);
    this.intervalId = null;
    if (!this.timeElement) {
      console.error(
        `Clock initialization failed: Element not found for selector "${selector}".`,
      );
      return;
    }
    if (autoStart) {
      this.start();
    }
  }

  updateTime() {
    if (!this.timeElement) {
      return;
    }
    const now = new Date();
    this.timeElement.textContent = now.toLocaleTimeString("en-GB", {
      hour12: false,
    });
  }

  start() {
    if (this.intervalId !== null) {
      return;
    }
    this.updateTime();
    this.intervalId = setInterval(() => {
      this.updateTime();
    }, 1000);
  }

  stop() {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  toggle() {
    if (this.intervalId === null) {
      this.start();
    } else {
      this.stop();
    }
  }

  destroy() {
    this.stop();
    this.timeElement = null;
  }
}
