// PlyrPlayer.js

import "/src/css/chunks/video.css";
import { $ } from "./Utils.js";
import "/src/js/vendor/plyr.js";
import "/src/css/vendor/plyr.css";

export default class PlyrPlayer {
  constructor(playerSelector, videoId, options = {}) {
    this.playerSelector = playerSelector;
    this.videoId = videoId;
    this.options = options;

    this.playerElement = null;
    this.player = null;
    this.playerReadyPromise = null;

    this.hasInteracted = false;
    this.isFading = false;

    // Volume fade settings
    this.fadeDuration = 1000;
    this.fadeSteps = 145;

    // Flags for differentiating user-triggered vs. programmatic actions
    this.isProgrammaticPlay = false;
    this.isProgrammaticPause = false;

    this.initializePlayer();
  }

  initializePlayer() {
    this.playerElement = $(this.playerSelector);
    if (!this.playerElement) {
      console.error(
        `Element with selector '${this.playerSelector}' not found.`,
      );
      return;
    }

    this.playerElement.setAttribute(
      "sandbox",
      "allow-same-origin allow-scripts allow-presentation",
    );
    this.playerElement.classList.add(this.videoId);
    this.playerElement.setAttribute("data-plyr-provider", "vimeo");
    this.playerElement.setAttribute("data-plyr-embed-id", this.videoId);

    this.player = new Plyr(this.playerSelector, this.options);

    this.playerReadyPromise = new Promise((resolve, reject) => {
      this.player.on("ready", () => {
        try {
          this.setupControlsInteractivity();
          this.setupPlayPauseListeners();
          // Start muted by default
          this.player.volume = 0;
          resolve(this.player);
        } catch (error) {
          console.error("Error during player setup:", error);
          reject(error);
        }
      });

      this.player.on("error", (event) => {
        console.error("Failed to initialize Plyr player:", event);
        reject(event);
      });
    });

    return this.playerReadyPromise;
  }

  setupControlsInteractivity() {
    const controlsElement = this.playerElement.querySelector(".plyr__controls");
    if (!controlsElement) return;
    controlsElement.addEventListener("click", () => {
      this.hasInteracted = true;
    });
    controlsElement.addEventListener("mouseleave", () => {
      if (this.hasInteracted) {
        controlsElement.classList.add("interacted");
      }
    });
  }

  setupPlayPauseListeners() {
    // When user presses play
    this.player.on("play", () => {
      if (!this.isProgrammaticPlay && !this.isFading) {
        // Fade from current volume (often 0) to last set or default volume
        //this.fadeVolumeTo(this.player.volume === 0 ? 1 : this.player.volume);
      }
      this.isProgrammaticPlay = false;
    });

    // When user presses pause
    this.player.on("pause", () => {
      if (!this.isProgrammaticPause && !this.isFading && this.player.playing) {
        // Fade out before pausing
        this.fadeVolumeTo(0).then(() => {
          this.isProgrammaticPause = true;
          this.player.pause();
        });
      }
      this.isProgrammaticPause = false;
    });
  }

  ready() {
    if (this.playerReadyPromise) {
      return this.playerReadyPromise;
    } else {
      return Promise.reject(new Error("Player not initialized"));
    }
  }

  async play() {
    if (!this.player) {
      console.error("Plyr player is not initialized.");
      return Promise.reject(new Error("Player not initialized"));
    }

    await this.ready();
    this.isProgrammaticPlay = true;
    // Already muted by default, now play and fade in
    try {
      await this.player.play();
      //await this.fadeVolumeTo(1); // Example: Fade to full volume
    } catch (error) {
      console.error("Failed to play video:", error);
      throw error;
    }
  }

  async pause() {
    if (!this.player) {
      console.error("Plyr player is not initialized.");
      return Promise.reject(new Error("Player not initialized"));
    }
    // Fade out, then pause
    await this.fadeVolumeTo(0);
    this.isProgrammaticPause = true;
    this.player.pause();
  }

  /**
   * Set the volume immediately without fading.
   * @param {number} targetVolume - A number between 0 and 1.
   */
  setVolume(targetVolume) {
    if (!this.player) {
      console.error("Plyr player is not initialized.");
      return;
    }
    if (
      typeof targetVolume !== "number" ||
      targetVolume < 0 ||
      targetVolume > 1
    ) {
      console.error("Volume must be a number between 0 and 1.");
      return;
    }

    this.isFading = false;
    this.player.volume = targetVolume;
  }

  /**
   * Fade the volume from the current volume to the target volume over a set duration.
   * @param {number} targetVolume - A number between 0 and 1.
   * @returns {Promise} Resolves when the fade completes.
   */
  fadeVolumeTo(targetVolume) {
    if (this.isFading) {
      // If a fade is already in progress, you could cancel or just ignore. We'll ignore for now.
      return Promise.resolve();
    }

    return new Promise((resolve) => {
      this.isFading = true;

      const initialVolume = this.player.volume;
      const target = Math.min(Math.max(targetVolume, 0), 1);
      const volumeStep = (target - initialVolume) / this.fadeSteps;
      let currentStep = 0;
      let lastAnimationTime = 0;

      const fade = (timestamp) => {
        if (!this.isFading) {
          // Fade was canceled
          return resolve();
        }

        if (!lastAnimationTime || timestamp - lastAnimationTime > 16) {
          currentStep += 1;
          const newVolume = initialVolume + volumeStep * currentStep;
          this.player.volume = Math.min(Math.max(newVolume, 0), 1);
          lastAnimationTime = timestamp;

          if (currentStep >= this.fadeSteps) {
            this.player.volume = target;
            this.isFading = false;
            resolve();
            return;
          }
        }
        requestAnimationFrame(fade);
      };
      requestAnimationFrame(fade);
    });
  }

  destroy() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
      this.playerElement = null;
      return Promise.resolve();
    } else {
      return Promise.resolve();
    }
  }
}
