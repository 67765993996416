// wasp-home.js

import { Config } from "./WASP/Config.js";
import * as Engine from "./WASP/Engine.js";
import PlyrPlayer from "./WASP/PlyrPlayer.js";
import "@lottiefiles/lottie-player";
import AnimLogo from "./WASP/AnimLogo.js";
import { $, isMobilePhone } from "./WASP/Utils.js";

document.body.style.overflow = "hidden";
const waspPlayers = {};
let animatedLogo;

function initAnimatedLogo() {
  const logoElement = $(Config.SELECTORS.spriteSheet);
  if (!logoElement) return;

  animatedLogo = new AnimLogo();
  animatedLogo.start();
}

function initLottiePlayer(lottieElement) {
  if (!lottieElement) return;
  lottieElement.addEventListener("rendered", () => {
    lottieElement.load("/files/HUD.json");
  });
}

async function setupHomeReel() {
  const playerElement = $(Config.SELECTORS.lottie);
  initLottiePlayer(playerElement);
  const videoQuality = isMobilePhone() ? "720p" : "auto";
  waspPlayers.home = new PlyrPlayer(
    Config.SELECTORS.homePlayer,
    Config.VIMEO_IDS.homeVideoId,
    {
      autoplay: false,
      autopause: false,
      hideControls: true,
      fullscreen: { enabled: false, fallback: false },
      tooltips: { controls: false, seek: false },
      loop: { active: true },
      muted: true,
      storage: { enabled: true },
      volume: 0,
      vimeo: {
        quality: videoQuality,
        loop: 1,
        autoplay: 0,
        muted: 1,
        gesture: "none",
        playsinline: true,
        byline: false,
        portrait: false,
        title: false,
        transparent: false,
        controls: 0,
        player_id: 0,
        app_id: 58479,
        dnt: 1,
      },
    },
  );
  try {
    Engine.handleFader();
    waspPlayers.home.play().then(() => {
      $(
        "section.home .plyr.plyr--full-ui.plyr--video.plyr--vimeo",
      ).classList.add("fade-in");
      if (playerElement) {
        playerElement.classList.add("ready");
        playerElement.play();
      }
    });
  } catch (error) {
    console.error("Error playing home reel video:", error);
  }
}

document.addEventListener("DOMContentLoaded", async () => {
  Engine.initClock();
  Engine.updateNavigationState();
  Engine.setupMenuToggle();
  Engine.highlightActiveNavLink();
  Engine.hackedTextEffect();
  initAnimatedLogo();
  await setupHomeReel();
  Engine.resizeListener(true);
  Engine.clickListener(waspPlayers.home);
});
