//Typewriter.js

import "/src/css/chunks/typewriter.css";
import { $ } from "./Utils.js";

export default class Typewriter {
  constructor(containerSelector, outputSelector) {
    this.container = $(containerSelector);
    this.textElement = $(outputSelector);
    this.fragments = [];
    this.currentFragmentIndex = 0;
    this.currentCharIndex = 0;
    this.visibleLines = [];
    this.maxVisibleLines = 5;
    this.lineHeight = this.getLineHeight();
    this.extractTextFragments();
  }

  extractTextFragments() {
    const elements = this.container.querySelectorAll("*");
    elements.forEach((element) => {
      if (element.textContent.trim()) {
        this.fragments.push({
          text: element.textContent.trim(),
          style: window.getComputedStyle(element),
        });
      }
    });
  }

  init() {
    this.typeCharacter();
  }

  typeCharacter() {
    if (this.currentFragmentIndex < this.fragments.length) {
      const fragment = this.fragments[this.currentFragmentIndex];
      const text = fragment.text;
      if (this.currentCharIndex < text.length) {
        const char = text[this.currentCharIndex];
        this.addCharacter(char, fragment.style);
        this.currentCharIndex++;
        setTimeout(() => this.typeCharacter(), 25);
      } else {
        this.currentCharIndex = 0;
        this.currentFragmentIndex++;
        this.newLine();
        setTimeout(() => this.typeCharacter(), 25);
      }
    } else {
      setTimeout(() => this.resetTypewriter(), 2000);
    }
  }

  addCharacter(char, style) {
    if (this.visibleLines.length === 0 || this.currentLineIsComplete) {
      this.createNewLine();
    }
    const span = document.createElement("span");
    span.textContent = char;
    span.style.color = style.color;
    span.style.whiteSpace = "pre";
    this.visibleLines[this.visibleLines.length - 1].appendChild(span);
  }

  createNewLine() {
    const lineDiv = document.createElement("p");
    lineDiv.style.height = `${this.lineHeight}px`;
    lineDiv.style.overflow = "hidden";
    this.textElement.appendChild(lineDiv);
    this.visibleLines.push(lineDiv);
    this.currentLineIsComplete = false;
    if (this.visibleLines.length > this.maxVisibleLines) {
      this.textElement.removeChild(this.visibleLines[0]);
      this.visibleLines.shift();
    }
  }

  newLine() {
    this.currentLineIsComplete = true;
  }

  getLineHeight() {
    const computedStyle = window.getComputedStyle(this.textElement);
    const lineHeight = computedStyle.getPropertyValue("line-height");
    return parseFloat(lineHeight) || 14;
  }

  resetTypewriter() {
    this.currentFragmentIndex = 0;
    this.currentCharIndex = 0;
    this.visibleLines = [];
    this.currentLineIsComplete = false;
    this.textElement.innerHTML = "";
    this.init();
  }
}
