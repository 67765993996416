//AnimLogo.js

import { $ } from "./Utils.js";
import { Config } from "./Config.js";

export default class AnimLogo {
  constructor() {
    this.logo = $(Config.SELECTORS.spriteSheet);
    this.frameIndex = 1;
    this.frameRate = 60;
    this.isAnimating = false;
    this.lastTimestamp = null;
    this.lastAnimationCycleTimestamp = performance.now();
    this.pauseDuration = this.getRandomPauseDuration();
    this.frameInterval = 1000 / this.frameRate;
    this.totalFrames = 10;
    this.animationCompleted = new Event("animationCompleted");
    this.frameWidth = 819.2;
    this.animationId = null;
    this.cycleTimeoutId = null;
  }

  start() {
    this.logo.style.opacity = "1";
    setTimeout(() => {
      if (!this.isAnimating) {
        this.isAnimating = true;
        this.lastTimestamp = null;
        requestAnimationFrame(this.animate.bind(this));
      }
    }, 250);
  }

  stop() {
    this.logo.style.opacity = "0";
    this.isAnimating = false;
    if (this.animationId) {
      cancelAnimationFrame(this.animationId);
    }
    if (this.cycleTimeoutId) {
      clearTimeout(this.cycleTimeoutId);
    }
  }

  animate(timestamp) {
    if (!this.isAnimating) {
      return;
    }
    if (!this.lastTimestamp) {
      this.lastTimestamp = timestamp;
    }
    const elapsed = timestamp - this.lastTimestamp;
    if (elapsed > this.frameInterval) {
      this.lastTimestamp = timestamp - (elapsed % this.frameInterval);
      this.updateAnimationState();
      this.updateLogoFrame();
    }
    if (
      !this.lastAnimationCycleTimestamp ||
      timestamp - this.lastAnimationCycleTimestamp > 16
    ) {
      this.lastAnimationCycleTimestamp = timestamp;
      this.animationId = requestAnimationFrame(this.animate.bind(this));
    }
  }

  updateLogoFrame() {
    const xOffset = (this.frameIndex - 1) * this.frameWidth;
    this.logo.style.backgroundPosition = `-${xOffset}px 0`;
  }

  updateAnimationState() {
    const now = performance.now();
    if (
      !this.isAnimating &&
      now - this.lastAnimationCycleTimestamp >= this.pauseDuration
    ) {
      this.startAnimationCycle(now);
    } else if (this.isAnimating && this.frameIndex >= this.totalFrames) {
      this.stopAnimationCycle();
    }
    if (this.isAnimating) {
      this.frameIndex = (this.frameIndex % this.totalFrames) + 1;
    }
  }

  startAnimationCycle(now) {
    this.totalFrames = Math.ceil(Math.random() * 8) + 2;
    this.isAnimating = true;
    this.lastAnimationCycleTimestamp = now;
    this.pauseDuration = this.getRandomPauseDuration();
    this.frameIndex = 1;
    this.lastTimestamp = null;
    requestAnimationFrame(this.animate.bind(this));
  }

  stopAnimationCycle() {
    this.isAnimating = false;
    if (Math.random() >= 0.75) {
      this.frameIndex = 1;
    } else {
      this.frameIndex = Math.ceil(Math.random() * 8) + 2;
    }
    this.logo.dispatchEvent(this.animationCompleted);
    this.cycleTimeoutId = setTimeout(() => {
      if (!this.isAnimating) {
        this.start();
      }
    }, this.pauseDuration);
  }

  getRandomPauseDuration() {
    return Math.random() * 5000 + 3000;
  }
}
