// Config.js

export const Config = {
  SELECTORS: {
    burger: ".hamburger",
    menu: "#slide-menu",
    spriteSheet: ".wasp-logo-spritesheet",
    clock: ".the-time",
    year: ".current-year",
    hackedText: ".link",
    canvas: "#canvas3d",
    homePlayer: "#wipeout",
    homeReel: "#showreel-mobile",
    waspPlayer: "#wasp-reel",
    aboutPlayer: "#about-reel",
    contactPlayer: "#contact-reel",
    fader: ".fader",
    error404: ".error404",
    videoWindow: "#video-window",
    typewriterContainer: "#typewriter-container",
    typewriterText: "#typewriter-text",
    header: "header",
    footer: "footer",
    lottie: "lottie-player",
    cables: ".cables",
  },

  URLS: {
    spline: import.meta.env.VITE_SPLINE_URL,
  },

  VIMEO_IDS: {
    homeVideoId: "1038184264",
    waspVideoId: "1001135510",
    aboutVideoId: "1027903275",
    contactVideoId: "1007518594",
  },

  SETTINGS: {
    revealDelay: 100,
    error404Timeout: 2500,
    scrollSpeed: 2000,
    logoFadeSpeed: 1,
    keyboardDebounceTime: 200,
  },
};
