// Engine.js

import { $, $$, isMobilePhone } from "./Utils.js";
import Clock from "./Clock.js";
import { Config } from "./Config.js";
import Hack from "./Hack.js";
import Typewriter from "./Typewriter.js";

const menu = $(Config.SELECTORS.menu);
const burger = $(Config.SELECTORS.burger);
const header = $(Config.SELECTORS.header);
const footer = $(Config.SELECTORS.footer);
const mobile = isMobilePhone();

/*
 * initClock
 */
export function initClock() {
  if (mobile) return;
  const timeElement = $(Config.SELECTORS.clock);
  const yearElement = $(Config.SELECTORS.year);
  if (timeElement) {
    const clock = new Clock(timeElement);
    clock.start();
  }
  if (yearElement) {
    yearElement.textContent = String(new Date().getFullYear());
  }
}

/*
 * handleFader
 */
export function handleFader() {
  const fader = $(Config.SELECTORS.fader);
  if (fader) fader.classList.add("faded");
}

/*
 * hackedTextEffect
 */
export function hackedTextEffect() {
  const hackedText = $(Config.SELECTORS.hackedText);
  if (hackedText) {
    const hackedTextEffect = new Hack(Config.SELECTORS.hackedText);
    hackedTextEffect.init();
  }
}

/*
 * initTypewriter
 */
export function initTypewriter() {
  const container = $(Config.SELECTORS.typewriterContainer);
  if (!container) return;

  const typewriter = new Typewriter(
    Config.SELECTORS.typewriterContainer,
    Config.SELECTORS.typewriterText,
  );
  typewriter.init();
}

/*
 * hideElement
 */
export function hideElement(selector) {
  const el = $(selector);
  if (!el) return;
  el.classList.add("hidden");
  el.setAttribute("aria-hidden", "true");
}

/*
 * highlightActiveNavLink
 */
export function highlightActiveNavLink() {
  const currentPath = normalizePath(window.location.pathname);

  const navLinks = $$("#main-menu a[data-page]");
  navLinks.forEach((link) => {
    const page = link.getAttribute("data-page");
    const pagePath = page === "home" ? "/" : `/${page}`;
    link.classList.toggle("active", currentPath === pagePath);
  });
}

/**
 * setMenuActiveState
 */
function setMenuActiveState(isActive, menuEl, burgerEl, headerEl, footerEl) {
  const method = isActive ? "add" : "remove";
  menuEl?.classList[method]("is-active");
  burgerEl?.classList[method]("is-active");
  headerEl?.classList[method]("active");
  footerEl?.classList[method]("active");
  if (
    menuEl &&
    menuEl.classList.contains("is-mobile") &&
    menuEl.classList.contains("is-active")
  ) {
    if (isActive) {
      burgerEl?.classList.add("disabled");
    } else {
      burgerEl?.classList.remove("disabled");
    }
  } else {
    burgerEl?.classList.remove("disabled");
  }
}

/**
 * setupMenuToggle
 */
export function setupMenuToggle() {
  if (!burger || !menu) return;
  burger.classList.remove("disabled");
  if (mobile) {
    menu.classList.add("is-mobile");
  }
  burger.addEventListener("click", () => {
    toggleHeaderFooterMenu(menu, burger, header, footer);
  });
  menu.addEventListener(
    "click",
    () => {
      if (
        menu.classList.contains("is-mobile") &&
        menu.classList.contains("is-active")
      ) {
        toggleHeaderFooterMenu(menu, burger, header, footer);
      }
    },
    true,
  );
}

/**
 * resizeListener
 */
export function resizeListener(listen = false) {
  handleResize();
  if (listen) {
    window.addEventListener("resize", handleResize);
  }
}

function handleResize() {
  if (!menu) return;
  if (window.innerWidth < 1200) {
    menu.classList.add("is-mobile");
    menu.classList.remove("is-active");
  } else {
    menu.classList.remove("is-mobile");
  }
}

/**
 * updateNavigationState
 */
export function updateNavigationState() {
  if (mobile) return;
  setTimeout(() => {
    toggleHeaderFooterMenu(menu, burger, header, footer);
  }, 1000);
}

/**
 * toggleHeaderFooterMenu
 */
export function toggleHeaderFooterMenu(menuEl, burgerEl, headerEl, footerEl) {
  const isCurrentlyActive = menuEl?.classList.contains("is-active");
  setMenuActiveState(!isCurrentlyActive, menuEl, burgerEl, headerEl, footerEl);
}

/**
 * hideHeaderFooterMenu
 */
export function hideHeaderFooterMenu(menuEl, burgerEl, headerEl, footerEl) {
  setMenuActiveState(false, menuEl, burgerEl, headerEl, footerEl);
}

/**
 * revealHeaderFooterMenu
 */
export function revealHeaderFooterMenu(menuEl, burgerEl, headerEl, footerEl) {
  setMenuActiveState(true, menuEl, burgerEl, headerEl, footerEl);
}

/*
 * clickListener
 */
export function clickListener(waspPlayer, spline) {
  document.body.addEventListener("click", async (event) => {
    const link = event.target.closest("a[data-link]");
    if (!link) return;
    event.preventDefault();
    const url = link.getAttribute("href");
    await navigateWithTransition(url, waspPlayer, spline);
  });
}

/*
 * navigateWithTransition
 */
async function navigateWithTransition(url, waspPlayer, spline) {
  const developmentMode =
    import.meta.env && import.meta.env.MODE !== "production";
  let finalURL = buildURL(url, developmentMode);
  if (menu) menu.classList.remove("is-active");
  if (waspPlayer) {
    const iframe = $(`iframe[src*='${waspPlayer.videoId}']`);
    if (iframe) {
      iframe.classList.add("fade-out");
    }
  }
  const navigate = () => {
    if (document.startViewTransition) {
      document.startViewTransition(() => {
        window.location.href = finalURL;
      });
    } else {
      window.location.href = finalURL;
    }
  };
  if (spline) {
    await spline.hideSpline();
    navigate();
  } else {
    setTimeout(navigate, 1000);
  }
}

/*
 * Utility Functions
 */

function normalizePath(pathname) {
  return pathname.replace(/\/$/, "").replace(/\.html$/, "") || "/";
}

function buildURL(url, developmentMode) {
  if (!developmentMode || url === "/") return url;
  if (url.includes("?")) {
    const [base, query] = url.split("?");
    return `${base}.html?${query}`;
  }
  return `${url}.html`;
}
