// Hack.js

import { $$ } from "./Utils.js";

export default class Hack {
  constructor(selector) {
    this.elements = $$(selector);
    this.letters =
      "-_#+&%$£0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    this.animationDuration = 600;
    this.frameRate = 24;
  }

  init() {
    this.elements.forEach((element) => {
      const originalText = element.dataset.value || element.textContent;
      element.addEventListener("mouseleave", () => {
        this.animateText(element, originalText);
      });
    });
  }

  animateText(element, originalText) {
    let frame = 0;
    const totalFrames = Math.round(
      (this.animationDuration / 1000) * this.frameRate,
    );
    const lettersArray = originalText.split("");
    const interval = setInterval(() => {
      lettersArray.forEach((_, index) => {
        if (index < frame * (lettersArray.length / totalFrames)) {
          lettersArray[index] = originalText[index];
        } else {
          lettersArray[index] = this.getRandomChar();
        }
      });
      element.textContent = lettersArray.join("");
      frame++;
      if (frame >= totalFrames) {
        clearInterval(interval);
        element.textContent = originalText;
      }
    }, 1000 / this.frameRate);
  }

  getRandomChar() {
    return this.letters.charAt(Math.floor(Math.random() * this.letters.length));
  }
}
