// Utils.js

/*
 * $
 * */
export function $(selector) {
  if (selector === window || selector === "window") {
    return window;
  }
  if (selector === document || selector === "document") {
    return document;
  }
  if (typeof selector === "string") {
    const element = document.querySelector(selector);
    if (!element) {
      //console.warn(`Element not found: ${selector}`);
      return null;
    }
    return element;
  }
  if (
    selector instanceof Element ||
    selector instanceof Document ||
    selector instanceof Window
  ) {
    return selector;
  }
  console.warn(`Invalid selector: ${selector}`);
  return null;
}

/*
 * $$
 * */
export function $$(selector) {
  const elements = document.querySelectorAll(selector);
  return Array.from(elements);
}

/*
 * toggleClass
 * */
export function toggleClass(element, className) {
  if (!(element instanceof Element)) {
    console.error(`Invalid element provided to toggleClass.`);
    return;
  }
  element.classList.toggle(className);
}

/*
 * toggleVisibility
 * */
export function toggleVisibility(element) {
  if (!(element instanceof Element)) {
    console.error(`Invalid element provided to toggleVisibility.`);
    return;
  }
  const currentVisibility = getComputedStyle(element).visibility;
  element.style.visibility =
    currentVisibility === "hidden" ? "visible" : "hidden";
}

/*
 * rangeMapper
 * */
export function rangeMapper(value, inputMin, inputMax, outputMin, outputMax) {
  const clampedValue = Math.max(inputMin, Math.min(value, inputMax));
  const ratio = (clampedValue - inputMin) / (inputMax - inputMin);
  return ratio * (outputMax - outputMin) + outputMin;
}

/*
 * debounce
 * */
export function debounce(func, wait = 100) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), wait);
  };
}

/*
 * throttle
 * */
export function throttle(func, delay = 100) {
  let lastCall = 0;
  return function (...args) {
    const context = this;
    const now = Date.now();
    if (now - lastCall >= delay) {
      lastCall = now;
      func.apply(context, args);
    }
  };
}

/*
 * isMobilePhone
 * */
export function isMobilePhone() {
  const ua = navigator.userAgent || window.opera;
  if (/iPhone|iPod/.test(ua) && !window.MSStream) {
    return true;
  }
  if (/android/i.test(ua)) {
    if (/mobile/i.test(ua)) {
      return true;
    }
  }
  return /Mobile|Phone|BlackBerry|IEMobile|Opera Mini/i.test(ua);
}

/*
 * hideElement
 * */
export function hideElement(selector) {
  const el = document.querySelector(selector);
  if (!el) return;
  el.classList.add("hidden");
  el.setAttribute("aria-hidden", "true");
}
